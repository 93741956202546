import React, { useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { StyledImageSeparator } from '../../pages/Transfers/TransferBoxTheme';
import {
  Grid,
  Button,
  Typography,
  Card,
  MenuItem,
  Stepper,
  Step,
  Divider,
  StepButton,
  InputAdornment,
  TextField,
  InputLabel,
  Select,
  FormControl,
  Alert
} from '@mui/material';

import SelectImageField from '../../Components/Generic/Fields/SelectImageField';
import { useTranslation } from 'react-i18next';
import { getCompanies } from '../../services/sideBar';
import { getCompaniesRoles } from '../../services/companies';
import { assignCompanyDelegate, getUserByEmail } from '../../services/users';
import { createCompanyInvitation } from '../../services/invitation';

function UserDelegationsCompanyStepper(props) {
  const { t } = useTranslation();
  const [step, handleStep] = React.useState(0);
  const [selectedCompany, setSelectedCompany] = React.useState(null);
  const [roles, setRoles] = React.useState([]);
  const [companies, setCompanies] = React.useState([]);
  const [selectedRol, setSelectedRol] = React.useState(null);
  const [userFound, setUserFound] = React.useState(null);
  const [email, setEmail] = React.useState("");
  const [showNotFound, setShowNotFound] = React.useState(false);
  const [assignError, setAssignError] = React.useState(false);
  const [assignWarning, setAssignWarning] = React.useState(false);
  const [inviteError, setInviteError] = React.useState(false);

  useEffect(() => {
    getCompanies()
      .then(res => setCompanies(res.data))
      .catch(err => console.log('error getCompanies', err));
  }, []);

  useEffect(() => {
    getCompaniesRoles(selectedCompany)
      .then(res => {
        if (res.data.length > 0) {
          let dataTemp = [];
          for (let item of res.data) {
            let itemTemp = {
              key: item.id,
              label: item.name
            };
            dataTemp.push(itemTemp);
          }
          setRoles(dataTemp);
        }
      })
      .catch(err => console.log('error getCompaniesRoles', err));
  }, [selectedCompany]);

  const searchBoxEmail = () => {
    setShowNotFound(false);
    setUserFound(null);
    setInviteError(false);
    getUserByEmail(email)
      .then(res => {
        if (res.data.length > 0) {
          setUserFound(res.data[0]);
          setShowNotFound(false);
        } else {
          setUserFound(null);
          setShowNotFound(true);
        }
      })
      .catch(err => console.log('error searchFromEmail', err));
  };

  const inviteUser = async () => {
    try {
      const res = await createCompanyInvitation({
        email,
        company_id: selectedCompany,
        permission_template_id: selectedRol,
      });
      props.showModal(res.data.message);
      if (props.handleAsign) {
        props.handleAsign();
      }
    } catch (error) {
      setInviteError(true);
      console.error('Error invitando al usuario', error);
    }
  };

  const asignRol = async () => {
    assignCompanyDelegate(email, selectedRol, selectedCompany)
      .then(res => {
        if (props.handleAsign) {
          props.handleAsign();
        }
      }).catch(err => {
        if (err.response.status === 403) {
          setAssignWarning(true);
        } else {
          setAssignError(true);
        }
        console.log('error users/delegations/company/asign', err);
      });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className="mt-0">
          <Grid item xs={12} className="mt-2">
            <Stepper nonLinear activeStep={step}>
              <Step key={0}>
                <StepButton color="inherit">
                  {t("component.roles.step.selectUser")}
                </StepButton>
              </Step>
              <Step key={1}>
                <StepButton color="inherit">
                  {t("component.roles.step.selectCompany")}
                </StepButton>
              </Step>
              <Step key={2}>
                <StepButton color="inherit">
                  {t("component.roles.step.selectRole")}
                </StepButton>
              </Step>
            </Stepper>
          </Grid>
          <Divider variant="middle" className="mt-4" style={{ marginBottom: '5px' }} />
        </Grid>
        {(step === 0) &&
          <>
            <Grid container display="flex" className="p-2" alignItems="center" justifyContent="center">
              <Grid item xs={12} className="mt-3" alignItems="center" justifyContent="center">
                <Typography variant="subtitle1" component="p">
                  {t("component.roles.user")}
                </Typography>
                <div className="d-flex align-center mt-2">
                  <TextField
                    name="user"
                    size="medium"
                    style={{ width: "100%" }}
                    value={email}
                    onChange={(val) => { setEmail(val.target.value); }}
                    placeholder={t("component.roles.user")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faSearch} color="#61B15A" />
                        </InputAdornment>
                      ),
                    }}
                  />&nbsp;
                  <Button onClick={searchBoxEmail} variant="contained">
                    {t('stepByStep.button.search')}
                  </Button>
                </div>
              </Grid>
              {(showNotFound) &&
                <div className="d-flex align-center mt-2" style={{ color: "orange" }}>
                  {t("component.roles.noEmailFound")}
                </div>
              }
              {(userFound) &&
                <div className="d-flex align-center mt-2" style={{ color: "orange" }}>
                  {t("component.roles.userFound")}
                </div>
              }
              <>
                {userFound !== null &&
                  <Grid item xs={12} className="mt-3">
                    <Grid component={Card} container>
                      <Grid item xs={9} className="p-4">
                        <Grid container>
                          <Grid item xs={4}>
                            <Typography variant="body1" component="p" color="text.primary" className="mb-2">
                              {t("component.roles.firstName")}
                            </Typography>
                            <Typography variant="body1" component="p" color="text.primary" className="mb-2">
                              {t("component.roles.lastName")}
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant="body1" component="p" className="mb-2">
                              <b>{userFound?.firstname}</b>
                            </Typography>
                            <Typography variant="body1" component="p" className="mb-2">
                              <b>{userFound?.lastname}</b>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                }
              </>
              <div style={{ alignSelf: 'flex-end', position: 'absolute', bottom: 35 }}>
                <Button type="button" onClick={() => { if (props.onCancel) { props.onCancel(); } }} sx={{ mr: 1 }} variant="outlined">
                  {t('stepByStep.button.cancel')}
                </Button>
                <Button type="button" disabled={userFound === null && !showNotFound} onClick={() => { handleStep(1); }} sx={{ mr: 1 }} variant="contained">
                  {t('stepByStep.button.next')}
                </Button>
              </div>
            </Grid>
          </>
        }
        {(step === 1) &&
          <Grid container display="flex" className="p-2" alignItems="center" justifyContent="center">
            <FormControl fullWidth>
              <InputLabel size={"small"} id={`demo-controlled-open-select-label`}>
                {t("component.roles.step.company")}
              </InputLabel>
              <Select
                size={"small"}
                label={t("component.roles.step.selectCompany")}
                labelId={`demo-controlled-open-select-label}`}
                value={selectedCompany}
                margin="dence"
                id="select"
                placeholder={t("component.roles.step.selectCompany")}
                onChange={(val) => setSelectedCompany(val.target.value)}>
                <MenuItem value="">
                  <em>Deseleccionar</em>
                </MenuItem>
                {companies.map((item) => <MenuItem key={item.id} value={item.id}>{item.company_name}</MenuItem>)}
              </Select>
            </FormControl>
            <div style={{ alignSelf: 'flex-end', position: 'absolute', bottom: 35 }}>
              <Button type="button" onClick={() => { if (props.onCancel) { props.onCancel(); } }} sx={{ mr: 1, mt: 3 }} variant="outlined">
                {t('stepByStep.button.cancel')}
              </Button>
              <Button type="button" onClick={() => { handleStep(0); }} sx={{ mr: 1, mt: 3 }} variant="outlined">
                {t('stepByStep.button.back')}
              </Button>
              <Button type="button" disabled={selectedCompany === null || selectedCompany === ""} sx={{ mr: 1, mt: 3 }} variant="contained" onClick={() => { handleStep(2); }}>
                {t('stepByStep.button.next')}
              </Button>
            </div>
          </Grid>
        }
        <Grid item xs={12} className="mt-0">
          {(step === 2) &&
            <Grid container display="flex" alignItems="center" justifyContent="center" className="p-2" gap={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel size={"small"}>{t("component.roles.step.selectRole")}</InputLabel>
                  <Select
                    size={"small"}
                    value={selectedRol}
                    onChange={(e) => setSelectedRol(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>Deseleccionar</em>
                    </MenuItem>
                    {roles.map(role => (
                      <MenuItem key={role.key} value={role.key}>{role.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {(assignError) &&
                <Grid item xs={12}>
                  <Alert severity='error' style={{ color: "red", fontWeight: "normal" }}>
                    Ha ocurrido un error, por favor intente nuevamente
                  </Alert>
                </Grid>
              }
              {(assignWarning) &&
                <Grid item xs={12}>
                  <Alert severity='warning' style={{ color: "black", fontWeight: "normal" }}>
                    El usuario no es válido
                  </Alert>
                </Grid>
              }
              {(inviteError) &&
                <Grid item xs={12}>
                  <Alert severity='error' style={{ color: "red", fontWeight: "normal" }}>
                    El usuario ya tiene una compania u establecimiento asignado.
                  </Alert>
                </Grid>
              }
              <Grid item xs={12} className="mt-3">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Button type="button" onClick={() => { if (props.onCancel) { props.onCancel(); setAssignError(false); setAssignWarning(false); } }} sx={{ mr: 1, mt: 3 }} variant="outlined">
                    {t('stepByStep.button.cancel')}
                  </Button>
                  <Button type="button" onClick={() => { setAssignError(false); setAssignWarning(false); handleStep(1); }} sx={{ mr: 1, mt: 3 }} variant="outlined">
                    {t('stepByStep.button.back')}
                  </Button>
                  <Button type="button" disabled={(selectedRol == null)} sx={{ mr: 1, mt: 3 }} variant="contained" onClick={() => { showNotFound ? inviteUser() : asignRol(); }}>
                    {showNotFound ? t('stepByStep.button.invite') : t('stepByStep.button.assign')}
                  </Button>
                </div>
              </Grid>
            </Grid>
          }
        </Grid>
      </Grid>
    </>
  );
}

export default UserDelegationsCompanyStepper;